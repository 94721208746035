// material-ui
import { useState } from 'react';
import { keyframes, useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Stack,
  Popper,
  IconButton,
  List,
  ListItemButton,
  Paper,
  ListItemText,
  Typography,
  Divider,
  ClickAwayListener, Tooltip
} from '@mui/material';
import LogoSection from 'layout/MainLayout/LogoSection';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ThemeButton from 'ui-component/ThemeButton';
import ProfileSection from 'layout/MainLayout/Header/ProfileSection';
import { IconMenu2 } from '@tabler/icons-react';
import Transitions from 'ui-component/extended/Transitions';
import MainCard from 'ui-component/cards/MainCard';
import { useMediaQuery } from '@mui/material';
import huggingface_logo from 'assets/images/huggingface_logo-noborder.svg';

import CloudIcon from '@mui/icons-material/Cloud';
import {
  Download,
  FreeBreakfast,
  NetworkCell,
  NetworkCheck,
  NetworkWifi,
  NightsStay,
  NoFlash
} from '@mui/icons-material';

const Header = () => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const account = useSelector((state) => state.account);
  const [open, setOpen] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenMenu = (event) => {
    setOpen(open ? null : event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };


  const bounce = keyframes`
      0%, 20%, 50%, 80%, 100% {
          transform: translateY(-12px);
      }
      40% {
          transform: translateY(-25px);
      }
      60% {
          transform: translateY(-14px);
      }
  `;

  return (
    <>
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box component="span" sx={{ flexGrow: 1 }}>
          <LogoSection />
        </Box>
      </Box>

      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />
      <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
        {isMobile ? (
          <>
            <ThemeButton />
            <IconButton onClick={handleOpenMenu}>
              <IconMenu2 />
            </IconButton>
          </>
        ) : (
          <>
            <Box sx={{ position: 'relative', display: 'inline-flex', alignItems: 'center', minHeight: 50 }}>
              <Tooltip title="集成下载器" arrow>
                <Button
                  component={Link}
                  size="large"
                  variant="text"
                  to="/"
                  color={pathname === '/' ? 'primary' : 'inherit'}
                  sx={{
                    fontSize: '1.2rem' // 调整字体大小，例如 1.5rem
                  }}
                >
                <span>
                  下载器
                </span>
                </Button>
                <Box
                  sx={{
                    position: 'relative',
                    display: 'inline-flex',
                    alignItems: 'center',
                    marginLeft: '-25px', // 左移整体位置
                    animation: `${bounce} 2s infinite` // 动画应用到容器
                  }}
                >
                  <CloudIcon
                    sx={{
                      color: 'skyblue',
                      fontSize: 20,
                      marginRight: '1px' // 与文字的间距
                    }}
                  />
                  <span
                    style={{
                      fontSize: '0.8rem', // 调整字体大小
                      color: pathname === '/boost' ? 'primary' : 'inherit'
                    }}
                  >
                    方案1
                  </span>
                </Box>
              </Tooltip>
            </Box>
            <Box sx={{ position: 'relative', display: 'inline-flex', alignItems: 'center', minHeight: 50 }}>
              <Tooltip title="加速器+批量解析工具" arrow>
                <Button
                  component={Link}
                  size="large"
                  variant="text"
                  to="/boost"
                  color={pathname === '/boost' ? 'primary' : 'inherit'}
                  sx={{
                    fontSize: '1.2rem' // 调整字体大小
                  }}
                >
                  加速器
                </Button>
                <Box
                  sx={{
                    position: 'relative',
                    display: 'inline-flex',
                    alignItems: 'center',
                    marginLeft: '-25px', // 左移整体位置
                    animation: `${bounce} 2s infinite` // 动画应用到容器
                  }}
                >
                  <CloudIcon
                    sx={{
                      color: 'skyblue',
                      fontSize: 20,
                      marginRight: '1px' // 与文字的间距
                    }}
                  />
                  <span
                    style={{
                      fontSize: '0.8rem', // 调整字体大小
                      color: pathname === '/boost' ? 'primary' : 'inherit'
                    }}
                  >
                    方案2
                  </span>
                </Box>
              </Tooltip>
            </Box>
            {/*<Button component={Link} variant="text" to="/about" color={pathname === '/about' ? 'primary' : 'inherit'}>*/}
            {/*  下载*/}
            {/*</Button>*/}
            <ThemeButton />
            {account.user ? (
              <>
                <Button component={Link} variant="contained" to="/panel" color="primary">
                  控制台
                </Button>
                <ProfileSection />
              </>
            ) : (
              <Button component={Link} variant="contained" to="/login" color="primary">
                登录
              </Button>
            )}
          </>
        )}
      </Stack>

      <Popper
        open={!!open}
        anchorEl={open}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
        style={{ width: '100vw' }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <ClickAwayListener onClickAway={handleCloseMenu}>
              <Paper style={{ width: '100%' }}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <List
                    component="nav"
                    sx={{
                      width: '100%',
                      maxWidth: '100%',
                      minWidth: '100%',
                      backgroundColor: theme.palette.background.paper,

                      '& .MuiListItemButton-root': {
                        mt: 0.5
                      }
                    }}
                    onClick={handleCloseMenu}
                  >
                    <ListItemButton
                      component={Link}
                      to="/"
                      style={{
                        backgroundImage: `url(${huggingface_logo})`,
                        backgroundSize: 'contain',   // 背景图按比例缩放
                        backgroundRepeat: 'no-repeat', // 防止重复
                        backgroundPosition: 'center' // 图片居中
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography variant="body2">
                            集成下载器(方案1)
                          </Typography>
                        }
                      />
                    </ListItemButton>

                    <ListItemButton component={Link} variant="text" to="/boost">
                      <ListItemText primary={<Typography variant="body2">加速器(方案2)</Typography>} />
                    </ListItemButton>
                    <Divider />
                    {account.user ? (
                      <ListItemButton component={Link} variant="contained" to="/panel" color="primary">
                        控制台
                      </ListItemButton>
                    ) : (
                      <ListItemButton component={Link} variant="contained" to="/login" color="primary">
                        登录
                      </ListItemButton>
                    )}
                  </List>
                </MainCard>
              </Paper>
            </ClickAwayListener>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default Header;
