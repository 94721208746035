import PropTypes from 'prop-types';
// material-ui
import { keyframes, useTheme } from '@mui/material/styles';

import { Avatar, Box, Button, ButtonBase, Tooltip } from '@mui/material';
// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';
import ThemeButton from 'ui-component/ThemeButton';
import { Link, useLocation } from 'react-router-dom';
// assets
import { IconMenu2 } from '@tabler/icons-react';
import CloudIcon from '@mui/icons-material/Cloud';
// 自定义向右斜着向上弯的箭头
// ==============================|| MAIN NAVBAR / HEADER ||============================== //


const bounce = keyframes`
      0%, 20%, 50%, 80%, 100% {
          transform: translateY(-12px);
      }
      40% {
          transform: translateY(-25px);
      }
      60% {
          transform: translateY(-14px);
      }
  `;
const Header = ({ handleLeftDrawerToggle }) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          <LogoSection />
        </Box>

        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              ...theme.typography.menuButton,
              transition: 'all .2s ease-in-out',
              '&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light
              }
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Box>

      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{
        display: { xs: 'none', md: 'inline-flex' }, // 移动端隐藏
        alignItems: 'center',
        minHeight: 50
      }}>
        <Tooltip title="集成下载器" arrow>
          <Button
            component={Link}
            size="large"
            variant="text"
            to="/"
            color={pathname === '/' ? 'primary' : 'inherit'}
            sx={{
              fontSize: '1.2rem' // 调整字体大小，例如 1.5rem
            }}
          >
                <span>
                  下载器
                </span>
          </Button>
          <Box
            sx={{
              position: 'relative',
              display: 'inline-flex',
              alignItems: 'center',
              marginLeft: '-25px', // 左移整体位置
              animation: `${bounce} 2s infinite` // 动画应用到容器
            }}
          >
            <CloudIcon
              sx={{
                color: 'skyblue',
                fontSize: 20,
                marginRight: '1px' // 与文字的间距
              }}
            />
            <span
              style={{
                fontSize: '0.8rem', // 调整字体大小
                color: pathname === '/boost' ? 'primary' : 'inherit'
              }}
            >
                    方案1
                  </span>
          </Box>
        </Tooltip>
      </Box>
      <Box sx={{
        position: 'relative',
        display: { xs: 'none', md: 'inline-flex' }, // 移动端隐藏
        alignItems: 'center',
        minHeight: 50
      }}>
        <Tooltip title="加速器+批量解析工具" arrow>
          <Button
            component={Link}
            size="large"
            variant="text"
            to="/boost"
            color={pathname === '/boost' ? 'primary' : 'inherit'}
            sx={{
              fontSize: '1.2rem' // 调整字体大小
            }}
          >
            加速器
          </Button>
          <Box
            sx={{
              position: 'relative',
              display: 'inline-flex',
              alignItems: 'center',
              marginLeft: '-25px', // 左移整体位置
              animation: `${bounce} 2s infinite` // 动画应用到容器
            }}
          >
            <CloudIcon
              sx={{
                color: 'skyblue',
                fontSize: 20,
                marginRight: '1px' // 与文字的间距
              }}
            />
            <span
              style={{
                fontSize: '0.8rem', // 调整字体大小
                color: pathname === '/boost' ? 'primary' : 'inherit'
              }}
            >
                    方案2
                  </span>
          </Box>
        </Tooltip>
      </Box>
      <ThemeButton />
      <ProfileSection />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
